import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";

const initialState = {
  userDeleteResponse: {
    status: false,
    message: "",
  },
  userDeleteSuccess: false,
  error: "",
  loading: false,
};

export const getNotification = createEncryptedAsyncThunk(
  "userdetails/getNotification",
  (params) => `api/Users/Notification?MobileNo=${params.currentUser.MobileNo}`,
  {
    method: "GET",
  }
);
// export const getProfileDetails = createEncryptedAsyncThunk(
//   'userdetails/getProfileDetails',
//   (params) => `account/GetUserDetails?userID=${params.currentUser.userId}&status=3`,
//   {
//     method: 'GET',
//   },
// );
export const getProfileDetails = createEncryptedAsyncThunk(
  "UserManagement/getUserProfileDetails",
  "account/GetUserDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      userDetailsID: params.currentUser.userId,
      documentRequired: 0,
    }),
  }
);
export const changePassword = createEncryptedAsyncThunk(
  "userdetails/changePassword",
  "account/ChangePassword",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      oldPassword: params.body.oldPassword,
      password: params.body.newPassword,
      ipAddress: params.location.ip,
    }),
  }
);
export const changeUserPassword = createEncryptedAsyncThunk(
  "userdetails/changeUserPassword",
  "account/ChangePassword",
  {
    prepareBody: (params) => ({
      userID: JSON.stringify(params.currentUser.userId),
      oldPassword: params.body.oldPassword,
      password: params.body.conPassword,
    }),
  }
);
export const changeDistributorPassword = createEncryptedAsyncThunk(
  "userdetails/changeDistributorPassword",
  "account/ChangePassword",
  {
    prepareBody: (params) => ({
      userID: JSON.stringify(params.currentUser.userId),
      oldPassword: params.body.oldPassword,
      password: params.body.conPassword,
    }),
  }
);
export const sendMPINOTP = createEncryptedAsyncThunk(
  "userdetails/sendMPINOTP",
  "Service/TwoWayAuthentication",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      type: "TPIN_RESET",
      mode: {
        mode1: "SMS",
        mode2: "EMAIL",
      },
      auth_mode: 1,
      receiverDetails: [
        {
          mobileNo: params.userinfo.primaryPhone,
          emailId: params.userinfo.emailAddress,
        },
      ],
      ipaddress: params.location.ip,
      substitutes: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    }),
  }
);

export const resetTpinRetailer = createEncryptedAsyncThunk(
  "userdetails/resetTpinRetailer",
  "account/ChangeTpin",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      tpinUserId: Number(params.currentUser.userId),
      oldTpin: "",
      newTpin: "",
      confirmTpin: "",
    }),
  }
);

export const resetTpin = createEncryptedAsyncThunk(
  "userdetails/resetTpin",
  "Service/ResetTpin",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      resetUserId: `${params.currentUser.userId}`,
      tpin: "",
      otp: params.body.otp,
      ipaddress: params.location.ip,
    }),
  }
);

export const setMPIN = createEncryptedAsyncThunk(
  "userdetails/setMPIN",
  "account/ChangeTpin",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      oldTpin: params.body.oldPin,
      newTpin: params.body.newMpin,
      confirmTpin: params.body.confMpin,
    }),
  }
);
export const getActiveServiceDetails = createAsyncThunk(
  "userdetails/complaintDelete",
  async (params) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}api/Admin/getplanbyuser?MobileNo=${params.currentUser.MobileNo}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          PT: process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${params.Usertoken}`,
        },
      }
    );
    const data = await response.json();
    const res = JSON.parse(data);
    return res;
  }
);

export const userDetailsSlice = createSlice({
  name: "userdetails",
  initialState,
  reducers: {
    userFetchSuccess(state, action) {
      return { ...state, user: action.payload.Data[0] };
    },
    userFetchFailed(state, action) {
      return {
        ...state,
        categoryResponse: { status: true, message: action.payload },
      };
    },
    userDeleteSuccess(state) {
      return { ...state, loading: false, userDeleteSuccess: true };
    },
    userDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        categoryDeleteResponse: { status: true, message: action.payload },
      };
    },
    userDeleteInitial(state) {
      return {
        ...state,
        userDeleteSucces: false,
        userDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  userFetchSuccess,
  userFetchFailed,
  userDeleteSuccess,
  userDeleteFailed,
  userDeleteInitial,
} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
