import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  EncryptPreLogin, DecryptPreLogin, } from '../../middleware/CryptoMiddleWare';

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}
export const fetchData = createAsyncThunk('data/fetchData', async (params) => {
  const rowData = {
    userID: params.currentUser.userGuid,
    userDetailsID: `${params.currentUser.userId}`,
    documentRequired: 0,
  };
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}account/GetUserDetails`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      PT: process.env.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${params.Usertoken}`,
    },
    body: await EncryptPreLogin(JSON.stringify(rowData)),
  });
  const res = await response.text();
  const data = await DecryptPreLogin(res);
  const encodedString = data;
  const UserData = JSON.parse(htmlDecode(encodedString));
  return UserData.data[0];
});

// Create the data slice
const dataSlice = createSlice({
  name: 'data',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default dataSlice.reducer;
