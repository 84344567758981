import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../../Utility/APIUtils";

const initialState = {
  reportSuccess: false,
  creditAmountResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};

// export const getTransactionHistoryLedger = createEncryptedAsyncThunk(
//   "reportSlice/getTransactionHistoryLedger",
//   "Wallet/GetLedgerDetail",
//   {
//     prepareBody: (params) => ({
//       userID: params.currentUser.userGuid,
//       ledger_user_id: params.currentUser.userId,
//       service_Id: 0,
//       transaction_type: "",
//       req_startDate: params.fromDate,
//       req_endDate: params.toDate,
//     }),
//   }
// );
export const getTransactionHistoryLedger = createEncryptedAsyncThunk(
  "reportSlice/getTransactionHistoryLedger",
  "Wallet/GetLedgerDetail",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.currentUser.userId,
      service_Id: 0,
      transaction_type: "",
      status: Array.isArray(params.transactionType)
        ? params.transactionType
        : [Number(params.transactionType)],
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const GetTdsTransaction = createEncryptedAsyncThunk(
  "reportSlice/GetTdsTransaction",
  "Wallet/GetLedgerTDSDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.currentUser.userId,
      service_Id: 0,
      transaction_type: "",
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const GetGstTransaction = createEncryptedAsyncThunk(
  "reportSlice/GetGstTransaction",
  "Wallet/GetLedgerGSTDetails",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.ledger_user_id || params.currentUser.userId,
      service_Id: 0,
      transaction_type: params.transaction_type || "",
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  }
);

export const getDMTTransectionHistory = createEncryptedAsyncThunk(
  "reportSlice/getDMTTransectionHistory",
  "account/GetDMTTransaction",
  {
    // transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      dmt_transaction_userid: params.currentUser.userId,
      txn_id: "",
      status: [0,1,2,3],
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);

export const getDMTTransectionHistoryPage = createEncryptedAsyncThunk(
  "reportSlice/getDMTTransectionHistoryPage",
  "account/GetDMTTransactionPage",
  {
    // transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      dmt_transaction_userid: params.currentUser.userId,
      pageless: true,
      txn_id: "",
      status: params.statustype,
      pageSize: 0,
      pageNumber: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);

export const getTransactionDetailsByAccount = createEncryptedAsyncThunk(
  "reportSlice/getTransactionDetailsByAccount",
  "account/GetAccountNoTransactionDetails",
  {
    // transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      accountNo: params.senderDetails,
      status: [0, 1, 2, 3],
      pageNumber: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);

export const getTransactionDetailsBySender = createEncryptedAsyncThunk(
  "reportSlice/getTransactionDetailsBySender",
  "account/GetTransactionDetailsBySender_Customer",
  {
    // transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      mobileNo: params.senderDetails,
      status: [0, 1, 2, 3],
      pageSize: 0,
      pageNumber: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);

export const getRechargeTransactionReciept = createEncryptedAsyncThunk(
  "reportSlice/getRechargeTransactionReciept",
  "account/GetBBPSReport",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bbps_userID: params.currentUser.userId,
      txn_id: params.TransactionID,
      txn_type: "",
      txn_mode: "",
      customerNo: "",
      startDate: null,
      endDate: null,
    }),
  }
);
export const getRechargeTransactionHistory = createEncryptedAsyncThunk(
  "reportSlice/getRechargeTransactionHistory",
  "account/GetBBPSReport",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bbps_userID: params.currentUser.userId,
      txn_id: "",
      txn_type: params.selectOperator,
      txn_mode: "",
      customerNo: "",
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);
export const getTopUpTransectionHistory = createEncryptedAsyncThunk(
  "reportSlice/getTopUpTransectionHistory",
  "account/GetTopUpReport",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      req_startDate: params.fromDate,
      reportUserId: params.currentUser.userId,
      req_endDate: params.toDate,
    }),
  }
);

export const updateTransaction = createEncryptedAsyncThunk(
  "reportSlice/updateTransaction",
  "Transaction/GetFailedTrans",
  {
    prepareBody: (params) => ({
      walletId: 0,
      transId: params.closeTrans.Transaction_ID,
      created_By: params.currentUser.UserID,
      userID: params.currentUser.userGuid,
      mode: 3,
    }),
  }
);
export const getPaygTransectionHistory = createEncryptedAsyncThunk(
  "reportSlice/getDMTTransectionHistory",
  "account/CFPGGetAllPG",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      pG_transaction_userid: params.currentUser.userId,
      transactionid: "",
      startDate: params.fromDate,
      endDate: params.toDate,
      status: [0, 1, 2],
    }),
  }
);
export const getOutStandingReportUser = createEncryptedAsyncThunk(
  "reportSlice/getOutStandingReportUser",
  "Service/OutstandingReport",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
    }),
  }
);

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return {
        ...state,
        creditAmountResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    reportDeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    reportDeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    reportDeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});

export const {
  creditAmountSuccess,
  creditAmountFailed,
  FetchSuccess,
  FetchFailed,
  reportDeleteSuccess,
  reportDeleteFailed,
  reportDeleteInitial,
} = reportSlice.actions;
export default reportSlice.reducer;
