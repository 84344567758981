import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Nav, NavLink, NavItem, Collapse } from "reactstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
// import SidebarData from '../sidebardata/SidebarData';
// import SidebarDistributor from '../sidebardata/DistributorSidebarData';
// import SuperDistributorData from '../sidebardata/SPDistributorData';
import HorizontalSidebarData from "../sidebardata/HorizontalSidebarData";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";
import {
  getProfileDetails,
  userDeleteInitial,
  userDeleteFailed,
  // getProfilePlanDetails,
} from "../../../slices/retailer/UserAppSlice/UserDetailsSlice";
import { fetchData } from "../../../slices/authSlice/dataSlice";
import Logo from "../../../assets/images/logos/logo.png";
import { logout } from "../../../slices/authSlice/userSilce";

/* eslint-disable */

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getActive = document.getElementsByClassName("activeLink");
  const [collapsed, setCollapsed] = React.useState(false);
  const [collapsed0, setCollapsed0] = React.useState(false);
  const [collapsed2, setCollapsed2] = React.useState(false);
  const [collapsed3, setCollapsed3] = React.useState(false);
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const userDeleteResponse = useSelector(
    (state) => state.userdetails.userDeleteSucces
  );
  const [, setProData] = useState([]);
  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  // const controlData = useSelector((state) => state.controlData.items);
  // const userData = useSelector((state) => state.userData.user);
  // const baseB4 = 'data:image/jpeg;base64,';

  useEffect(() => {
    dispatch(fetchData({ Usertoken, currentUser }));
  }, [dispatch]);

  const Banking = [
    {
      title: "DMT",
      href: "/DMT/DMTDashboard",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "AEPS",
      href: "/AEPS/AEPSsearch",
      icon: <Icon.ChevronRight />,
      id: 13.2,
      collapisble: false,
    },
    {
      title: "MATM",
      href: "#",
      icon: <Icon.ChevronRight />,
      id: 13.2,
      collapisble: false,
    },
    {
      title: "CMS",
      href: "/Agent/GetCMSURL",
      icon: <Icon.ChevronRight />,
      id: 13.2,
      collapisble: false,
    },
    {
      title: "Add Money",
      href: "#",
      icon: <Icon.ChevronRight />,
      id: 13.2,
      collapisble: false,
    },
    {
      title: "UPI",
      href: "#",
      icon: <Icon.ChevronRight />,
      id: 13.2,
      collapisble: false,
    },
  ];
  const items = [
    {
      title: "Mobile Recharge",
      href: "/Recharge/MobileRecharge",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
    {
      title: "DTH Recharge",
      href: "/Recharge/DthRecharge",
      icon: <Icon.ChevronRight />,
      id: 13.2,
      collapisble: false,
    },
    {
      title: "Bill Payments",
      href: "/Agent/BillPayments",
      icon: <Icon.ChevronRight />,
      id: 13.3,
      collapisble: false,
    },
    {
      title: "Bill Payments-2",
      href: "/Agent/BillPayments2",
      icon: <Icon.ChevronRight />,
      id: 13.3,
      collapisble: false,
    },
  ];

  const setting = [
    {
      title: "Change Password",
      href: "/Agent/ChangePassword",
      icon: <Icon.ChevronRight />,
      id: 12.1,
      collapisble: false,
    },
    {
      title: "Set TPIN",
      href: "/Agent/SetTPIN",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Reset TPIN",
      href: "/Agent/ResetTPIN",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Raise Complaint",
      href: "/Agent/ComplaintListAgent",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
  ];
  const support = [
    {
      title: "Complaint & Support",
      href: "/Retailer/ComplaintsView",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
  ];

  const agentReports = [
    {
      title: "Ledger Report",
      href: "/Reports/LedgerReport",
      icon: <Icon.ChevronRight />,
      id: 12.1,
      collapisble: false,
    },
    {
      title: "DMT Transaction Report",
      href: "/Reports/DMTTransactionReports",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "DMT Transaction Hold Cases Reports",
      href: "/Reports/DMTTransactionHoldCasesReports",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Transaction By Account & Customer Number",
      href: "/Reports/ByAccountNumber",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    // {
    //   title: "AEPS Transaction",
    //   href: "/Reports/AEPSTransactionReports",
    //   icon: <Icon.ChevronRight />,
    //   id: 12.2,
    //   collapisble: false,
    // },
    // {
    //   title: "PG Transaction",
    //   href: "/Reports/PaymentGetwayReports",
    //   icon: <Icon.ChevronRight />,
    //   id: 12.2,
    //   collapisble: false,
    // },
    {
      title: "TDS Report",
      href: "/Reports/TDSReports",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "GST Report",
      href: "/Reports/GSTReports",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "BBPS Reports",
      href: "/Reports/RechargeReport",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Outstanding Reports",
      href: "/Reports/OutStandingReport",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Top Up Report",
      href: "/Reports/TopUpReport",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Cashout Reports",
      href: "/Agent/CashoutTransaction",
      icon: <Icon.ChevronRight />,
      id: 13.1,
      collapisble: false,
    },
  ];

  const distributorReports = [
    {
      title: "Ledger Report",
      href: "/Reports/LedgerReportDistributor",
      icon: <Icon.ChevronRight />,
      id: 12.1,
      collapisble: false,
    },
    {
      title: "Top Up Report",
      href: "/Reports/DistributorTopUpReport",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "TDS Report",
      href: "/Reports/TDSReportDistributor",
      icon: <Icon.ChevronRight />,
      id: 12.3,
      collapisble: false,
    },
    {
      title: "GST Report",
      href: "/Reports/GSTReportDistributor",
      icon: <Icon.ChevronRight />,
      id: 12.4,
      collapisble: false,
    },
  ];

  const superDistributorReports = [
    {
      title: "Ledger Report",
      href: "/Reports/LedgerSuperReportDistributor",
      icon: <Icon.ChevronRight />,
      id: 12.1,
      collapisble: false,
    },
    {
      title: "Top Up Report",
      href: "/Reports/SuperDisTopUpReport",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "TDS Report",
      href: "/Reports/TDSReportSuperDistributor",
      icon: <Icon.ChevronRight />,
      id: 12.3,
      collapisble: false,
    },
    {
      title: "GST Report",
      href: "/Reports/GSTReportSuperDistributor",
      icon: <Icon.ChevronRight />,
      id: 12.4,
      collapisble: false,
    },
  ];

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  // const toggle1 = () => {
  //   setCollapsed1(!collapsed1);
  // };
  const toggle2 = () => {
    setCollapsed2(!collapsed2);
  };
  const toggle3 = () => {
    setCollapsed3(!collapsed3);
  };
  const toggle0 = () => {
    setCollapsed0(!collapsed0);
  };
  useEffect(() => {
    if (userDeleteResponse === true) {
      dispatch(getProfileDetails({ currentUser, Usertoken }))
        .unwrap()
        .then((res) => {
          if (res.status === true) {
            setProData(res.data[0].ProfilePic);
            dispatch(userDeleteInitial());
          } else {
            dispatch(userDeleteFailed(res));
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [userDeleteResponse]);
  useEffect(() => {
    dispatch(getProfileDetails({ currentUser, Usertoken }))
      .unwrap()
      .then((res) => {
        if (res.status === "true") {
          dispatch(userDeleteInitial());
          setProData(res.data[0].ProfilePic);
        } else {
          dispatch(userDeleteFailed(res));
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (userDeleteResponse === true) {
      dispatch(getProfileDetails({ currentUser, Usertoken }))
        .unwrap()
        .then((res) => {
          if (res.status === "true") {
            dispatch(userDeleteInitial());
            setProData(res.data);
          } else {
            dispatch(userDeleteFailed(res));
            setLoading(false);
          }
        })
        .catch(() => {});
    }
  }, [userDeleteResponse]);
  const handleLogoutClick = () => {
    window.addEventListener("beforeunload", () => {
      dispatch(logout());
    });
    navigate("/", { replace: true });
    window.location.reload();
  };
  return (
    <div className={`sidebarBox   ${isFixed ? "fixedSidebar" : ""}`}>
      <SimpleBar style={{ height: "100%" }}>
        <div className=" d-flex align-items-center justify-content-center logo-space mb-2 mt-2">
          {currentUser.RoleName === "Admin" ? (
            <Link to="/Dashboards/AdminDashboard">
              <img src={Logo} alt="profile" width="70px" />
              <h1>Logo</h1>
            </Link>
          ) : null}
          {currentUser.RoleName === "Super Distributor" ? (
            <Link to="/Dashboards/SuperDistributorDashboard">
              <img src={Logo} alt="profile" width="70px"></img>
            </Link>
          ) : null}
          {currentUser.RoleName === "Distributor" ? (
            <Link to="/Dashboards/DistributorDashboard">
              <img src={Logo} alt="profile" width="70px"></img>
            </Link>
          ) : null}
          {currentUser.RoleName === "Agent" ? (
            <Link to="/Dashboards/RetailerDashboard">
              <img src={Logo} alt="profile" width="70px"></img>
            </Link>
          ) : null}
          {currentUser.RoleName === "Admin" ? (
            <Button
              close
              size="sm"
              className="ms-auto d-none"
              // onClick={() => dispatch(ToggleMobileSidebar())}
            />
          ) : (
            <img src={Logo} alt="profile" width="70px"></img>
          )}
        </div>
        <div className="mb-4 pb-5 mt-1" loading={loading}>
          <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
            {currentUser.role === "6" && currentUser.kycStatus ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Dashboards/RetailerDashboard"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Home />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Beneficiary/CopyBeneficiary" className="gap-2 d-none"> */}
                  <NavLink tag={Link} to="#" className="gap-2 d-none">
                    <span className="sidebarIcon d-flex align-items-center">
                      {" "}
                      <Icon.Copy />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Copy Beneficiary</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/SelfBank/RetailorBankList"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      {" "}
                      <Icon.User />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Agent Self Bank </span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Agent/AEPSPayout"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      {" "}
                      <Icon.Airplay />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>AEPS Payout</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Agent/AEPSToDMTTransfer"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      {" "}
                      <Icon.MousePointer />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>AEPS Wallet to DMT</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                {/*

                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Reports/AllRetailorReports"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Agent Reports</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                */}

                <NavItem
                  className={collapsed0 && getActive ? "activeParent" : ""}
                  loading={loading}
                >
                  <NavLink className="cursor-pointer gap-2" onClick={toggle0}>
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span className="d-block">Agent Reports</span>
                        <span className="ms-auto">
                          <i
                            className={`bi fs-8 ${
                              collapsed0
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </NavLink>
                  <Collapse
                    isOpen={collapsed0}
                    navbar
                    tag="ul"
                    className="subMenu"
                  >
                    {agentReports.map((item) => (
                      <NavItem
                        key={item.title}
                        className={`hide-mini ${
                          location.pathname === item.href ? "activeLink" : ""
                        }`}
                      >
                        <NavLink tag={Link} to={item.href} className="gap-2">
                          <span className="sidebarIcon">{item.icon}</span>
                          <span className="hide-mini text-wrap">
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </NavItem>

                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Agent/BalanceRequestHistory"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.DollarSign />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                {/* <NavItem
                  className={collapsed1 && getActive ? 'activeParent' : ''}
                  loading={loading}
                >
                  <NavLink className="cursor-pointer gap-2" onClick={toggle1}>
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.PhoneCall />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span className="d-block">Reports</span>
                        <span className="ms-auto">
                          <i
                            className={`bi fs-8 ${
                              collapsed1 ? 'bi-chevron-down' : 'bi-chevron-right'
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </NavLink>
                  <Collapse isOpen={collapsed1} navbar tag="ul" className="subMenu">
                    {report.map((item) => (
                      <NavItem
                        key={item.title}
                        className={`hide-mini ${
                          location.pathname === item.href ? 'activeLink' : ''
                        }`}
                      >
                        <NavLink tag={Link} to={item.href} className="gap-2">
                          <span className="sidebarIcon">{item.icon}</span>
                          <span className="hide-mini">
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </NavItem> */}
                <NavItem
                  className={collapsed2 && getActive ? "activeParent" : ""}
                  loading={loading}
                >
                  <NavLink className="cursor-pointer gap-2" onClick={toggle2}>
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Settings />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span className="d-block">Setting</span>
                        <span className="ms-auto">
                          <i
                            className={`bi fs-8 ${
                              collapsed2
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </NavLink>
                  <Collapse
                    isOpen={collapsed2}
                    navbar
                    tag="ul"
                    className="subMenu"
                  >
                    {setting.map((item) => (
                      <NavItem
                        key={item.title}
                        className={`hide-mini ${
                          location.pathname === item.href ? "activeLink" : ""
                        }`}
                      >
                        <NavLink tag={Link} to={item.href} className="gap-2">
                          <span className="sidebarIcon">{item.icon}</span>
                          <span className="hide-mini">
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </NavItem>
                {/*
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Reports/AllRetailorReports"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Hexagon />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Privacy Policy</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Reports/AllRetailorReports"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Shield />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Terms & Condition</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                */}
                <NavItem>
                  <NavLink onClick={handleLogoutClick} className="gap-2">
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.LogOut />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Logout</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    collapsed3 && getActive ? "activeParent d-none" : "d-none"
                  }
                  loading={loading}
                >
                  <NavLink className="cursor-pointer gap-2" onClick={toggle3}>
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.HelpCircle />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span className="d-block">Support</span>
                        <span className="ms-auto">
                          <i
                            className={`bi fs-8 ${
                              collapsed3
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </NavLink>
                  <Collapse
                    isOpen={collapsed3}
                    navbar
                    tag="ul"
                    className="subMenu"
                  >
                    {support.map((item) => (
                      <NavItem
                        key={item.title}
                        className={`hide-mini ${
                          location.pathname === item.href ? "activeLink" : ""
                        }`}
                      >
                        <NavLink tag={Link} to={item.href} className="gap-2">
                          <span className="sidebarIcon">{item.icon}</span>
                          <span className="hide-mini">
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </NavItem>
              </>
            ) : currentUser.role === "5" && currentUser.kycStatus ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Dashboards/DistributorDashboard"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Home />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/ViewRetailerDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UserPlus />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Add Agent</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/ManageCreditDebitDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Manage Credit/Debit</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/CreditDetailsDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Credit Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem
                  className={collapsed0 && getActive ? "activeParent" : ""}
                  loading={loading}
                >
                  <NavLink className="cursor-pointer gap-2" onClick={toggle0}>
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span className="d-block">Reports</span>
                        <span className="ms-auto">
                          <i
                            className={`bi fs-8 ${
                              collapsed0
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </NavLink>
                  <Collapse
                    isOpen={collapsed0}
                    navbar
                    tag="ul"
                    className="subMenu"
                  >
                    {distributorReports.map((item) => (
                      <NavItem
                        key={item.title}
                        className={`hide-mini ${
                          location.pathname === item.href ? "activeLink" : ""
                        }`}
                      >
                        <NavLink tag={Link} to={item.href} className="gap-2">
                          <span className="sidebarIcon">{item.icon}</span>
                          <span className="hide-mini">
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/CommissionReport" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/CommisionReportDistributor"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Commission Report</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Distributor/ChangePasswordDistributor" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/Distributor/ChangePasswordDistributer"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Settings />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Change Password</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Bank/DistributorBankList" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/Distributor/DistributorBankList"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UploadCloud />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Self Bank Master</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/BalanceRequestHistoryDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink tag={Link} to="/Funds/FundRequestDistributor" className="gap-2">
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem> */}

                <NavItem>
                  {/* <NavLink tag={Link} to="/Distributor/TransactionReportAgent" className="gap-2"> */}
                  <NavLink tag={Link} to="#" className="gap-2 d-none">
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Agent Trans Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
              </>
            ) : currentUser.role === "4" && currentUser.kycStatus ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Dashboards/SuperDistributorDashboard"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Home />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    // to="/SuperDistributor/ViewDistributor"
                    to="/dashboards/SuperViewRetailerDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UserPlus />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Add Distributor</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/SuperManageCreditDebitDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Manage Credit/Debit</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/SuperCreditDetailsDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Credit Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem
                  className={collapsed0 && getActive ? "activeParent" : ""}
                  loading={loading}
                >
                  <NavLink className="cursor-pointer gap-2" onClick={toggle0}>
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span className="d-block">Reports</span>
                        <span className="ms-auto">
                          <i
                            className={`bi fs-8 ${
                              collapsed0
                                ? "bi-chevron-down"
                                : "bi-chevron-right"
                            }`}
                          />
                        </span>
                      </div>
                    </span>
                  </NavLink>
                  <Collapse
                    isOpen={collapsed0}
                    navbar
                    tag="ul"
                    className="subMenu"
                  >
                    {superDistributorReports.map((item) => (
                      <NavItem
                        key={item.title}
                        className={`hide-mini ${
                          location.pathname === item.href ? "activeLink" : ""
                        }`}
                      >
                        <NavLink tag={Link} to={item.href} className="gap-2">
                          <span className="sidebarIcon">{item.icon}</span>
                          <span className="hide-mini">
                            <span>{item.title}</span>
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Collapse>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/SPCommissionReport" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/CommisionSuperReportDistributor"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Commission Report</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/SuperDistributor/ChangePasswordSP" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/SuperDistributor/ChangePasswordSP"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Settings />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Change Password</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Bank/SPDistributorBankList" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/SuperDistributor/SPDistributorBankList"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UploadCloud />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Self Bank Master</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Funds/FundRequestSuperDistributor" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/SuperDistributor/BalanceRequestHistorySuper"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink tag={Link} to=" " className="gap-2">
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Agent Trans Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem> */}
              </>
            ) : currentUser.role === "1" ? (
              <>
                {HorizontalSidebarData.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div
                        className="navCaption text-uppercase text-wrap mt-4"
                        key={navi.caption}
                      >
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={
                        location.pathname === navi.href ? "activeLink" : ""
                      }
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : (
              <h3
                className="text-warning fw-bold"
                style={{ marginLeft: "1.7rem" }}
              >
                User KYC is Pending
              </h3>
            )}
          </Nav>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
